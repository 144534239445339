.project-list-container
{
    margin: 32px;
    width: 50%;
    padding-left:25%;
}

.project-container {
    background-color: rgb(50, 50, 50);
    color: rgb(212, 212, 212);
    border: beige;
    border-width: 1px;
    border-radius: 5px;
    margin: 2px;
    padding: 8px;
    width: 100%;
    text-align: left;
}

.project-container-button {
    background-color: rgb(50, 50, 50);
    color: rgb(212, 212, 212);
    border: beige;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
}

.project-app-icon {
    width: 128px;
    height: 128px;
}