.dashboard {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashboard__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .mainArea{
    align-items: center;
    justify-content: center;
  }
  .dashboard__sidebar {
    margin: 0;
    padding: 0;
    width: 20%;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    }
  .dashboard__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .dashboard div {
    margin-top: 7px;
  }
  
  .dashboard__img {
    width: 64px;
    height: 64px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
  }

  
  .dashboard__img_small {
    width: 32px;
    height: 32px;
  }