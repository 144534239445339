
.loglist-container
{
    margin: 32px;
    width: 75%;
    padding-left:12%;
}

.log-container {
    background-color: rgb(50, 50, 50);
    color: rgb(212, 212, 212);
    border: beige;
    border-width: 1px;
    border-radius: 5px;
    margin: 2px;
    padding: 8px;
    width: 100%;
    text-align: left;
}
.log-container:hover {
    background-color: rgb(60, 60, 60);
    /* color: rgb(255, 255, 255); */
}

.log-container-button {
    background-color: rgba(50, 50, 50, 0);
    color: rgb(212, 212, 212);
    border: beige;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
}

.log-container-table {
    width: 100%;
    table-layout:fixed;
}
.log-container > ul{
    list-style: none;
}
.log-icon-container{
    width: 32px;
    max-width: 32px;
}

.log-version-container{
    padding-left: 18px;
    width: 72px;
}
.log-version {
    background-color: blueviolet;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    font-size: 14px;
    margin: 1px;
    padding: 4px;
    mix-blend-mode: difference
}
.log-version:after {
    /* This value is the OPPOSITE color of our background */
    color: rgb(255, 255, 255); 
    mix-blend-mode: difference;
}

.log-time-container{
    padding-left: 18px;
    width: 192px;
}

.log-time {
    font-size: medium;
}
.app-icon-small {
    width: 32px;
    height: 32px;
}


.log-stack-toggle {
    float: right;
    background-color: dimgray;
    color: gainsboro;
    border-radius: 4px;
    border-width: 1px;
    border-color: #919191;
    border-style: solid;
}

.log-stack {
    background-color: rgb(75, 75, 75);
    font-size: small;
    white-space: pre-line;
    padding: 8px;
    margin-top: 4px;
}
.log-platform{
    float:right
}
.log-platform-icon{
    height: 32px;
}

table,
th,
td {
    border: 0px solid rgb(243, 243, 243);
}