.log-stack-toggle {
    float: right;
    background-color: dimgray;
    color: gainsboro;
    border-radius: 4px;
    border-width: 1px;
    border-color: #919191;
    border-style: solid;
}
.log-stack {
    background-color: rgb(75, 75, 75);
    font-size: small;
    white-space: pre-line;
    padding: 8px;
    margin-top: 4px;
}

.log-id{
    font-size:x-small;
    position:relative;
    
    right:0px;
}